import { useRef, useMemo } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Vector2, Vector3, ShaderMaterial, Color } from 'three';

// Vertex Shader
const vertexShader = `
  varying vec2 vUv;
  varying vec3 vPosition;

  void main() {
    vUv = uv;
    vPosition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

// Fragment Shader
const fragmentShader = `
  uniform float time;
  uniform vec2 resolution;
  uniform vec3 colorA;
  uniform vec3 colorB;
  uniform vec3 colorC;

  varying vec2 vUv;
  varying vec3 vPosition;

  #define PI 3.14159265359

  // Simplex 3D Noise
  vec4 permute(vec4 x) {
    return mod(((x*34.0)+1.0)*x, 289.0);
  }
  vec4 taylorInvSqrt(vec4 r) {
    return 1.79284291400159 - 0.85373472095314 * r;
  }
  float snoise(vec3 v) {
    const vec2 C = vec2(1.0/6.0, 1.0/3.0);
    const vec4 D = vec4(0.0, 0.5, 1.0, 2.0);

    // First corner
    vec3 i  = floor(v + dot(v, C.yyy));
    vec3 x0 = v - i + dot(i, C.xxx);

    // Other corners
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min(g.xyz, l.zxy);
    vec3 i2 = max(g.xyz, l.zxy);

    vec3 x1 = x0 - i1 + 1.0 * C.xxx;
    vec3 x2 = x0 - i2 + 2.0 * C.xxx;
    vec3 x3 = x0 - 1. + 3.0 * C.xxx;

    // Permutations
    i = mod(i, 289.0);
    vec4 p = permute(permute(permute(
              i.z + vec4(0.0, i1.z, i2.z, 1.0))
            + i.y + vec4(0.0, i1.y, i2.y, 1.0))
            + i.x + vec4(0.0, i1.x, i2.x, 1.0));

    // Gradients
    float n_ = 1.0/7.0;
    vec3  ns = n_ * D.wyz - D.xzx;

    vec4 j = p - 49.0 * floor(p * ns.z *ns.z);

    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_);

    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);

    vec4 b0 = vec4(x.xy, y.xy);
    vec4 b1 = vec4(x.zw, y.zw);

    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));

    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww;

    vec3 p0 = vec3(a0.xy, h.x);
    vec3 p1 = vec3(a0.zw, h.y);
    vec3 p2 = vec3(a1.xy, h.z);
    vec3 p3 = vec3(a1.zw, h.w);

    // Normalise gradients
    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2,p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;

    // Mix final noise value
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot(m*m, vec4(dot(p0,x0), dot(p1,x1), dot(p2,x2), dot(p3,x3)));
  }

  // Enhanced noise function for more organic movement
  float organicNoise(vec3 p) {
    float n = snoise(p);
    float n2 = snoise(p * 2.0 + n);
    return mix(n, n2, 0.5);
  }

  // Rotation matrix for dynamic movement
  mat2 rotate2D(float angle) {
    float s = sin(angle);
    float c = cos(angle);
    return mat2(c, -s, s, c);
  }

  void main() {
    vec2 uv = vUv;
    
    // Create dynamic UV distortion
    vec2 distortedUV = uv;
    distortedUV += vec2(
      organicNoise(vec3(uv * 3.0, time * 0.1)) * 0.1,
      organicNoise(vec3(uv * 3.0 + 100.0, time * 0.1)) * 0.1
    );
    
    // Multiple layers of flowing noise with different speeds and scales
    float n1 = organicNoise(vec3(distortedUV * 3.0, time * 0.1));
    float n2 = organicNoise(vec3(distortedUV * 2.0 - time * 0.08, time * 0.05));
    float n3 = organicNoise(vec3(distortedUV * 4.0 + time * 0.1, time * 0.07));
    float n4 = organicNoise(vec3(rotate2D(time * 0.05) * distortedUV * 5.0, time * 0.12));
    
    // Create dynamic masks with smooth transitions
    float mask1 = smoothstep(0.3, 0.7, n1) * 0.7;
    float mask2 = smoothstep(0.4, 0.6, n2) * 0.8;
    float mask3 = smoothstep(0.3, 0.8, n3) * 0.6;
    float mask4 = smoothstep(0.2, 0.9, n4) * 0.5;
    
    // Dark base color with slight variation
    vec3 baseColor = vec3(0.02, 0.02, 0.04) + n4 * 0.02;
    
    // Complex color mixing with dynamic weights
    vec3 color = baseColor;
    float flowSpeed = sin(time * 0.25) * 0.5 + 0.5;
    
    // Layer 1: Primary flow
    color = mix(color, colorA * 0.5, mask1 * (0.7 + flowSpeed * 0.3));
    
    // Layer 2: Secondary patterns
    color = mix(color, colorB * 0.5, mask2 * (0.6 + sin(time * 0.35) * 0.2));
    
    // Layer 3: Detailed highlights
    color = mix(color, colorC * 0.5, mask3 * mask4 * (0.5 + cos(time * 0.15) * 0.2));
    
    // Dynamic glow effect
    float glow = pow(max(0.0, 1.0 - length(distortedUV - 0.5) * 1.5), 3.0);
    vec3 glowColor = mix(colorB, colorC, sin(time * 0.15) * 0.5 + 0.5);
    color += glow * glowColor * 0.15 * (0.8 + sin(time * 0.75) * 0.2);
    
    // Atmospheric effect
    float atmosphere = pow(1.0 - length(uv - 0.5), 2.0) * (0.5 + sin(time * 0.1) * 0.1);
    color += atmosphere * colorA * 0.05;
    
    // Enhanced vignette with subtle movement
    float vignette = pow(1.0 - length(distortedUV - 0.5) * (1.3 + sin(time * 0.1) * 0.1), 2.0);
    color *= vignette * 0.9;
    
    // Subtle color correction
    color = pow(color, vec3(0.95)); // Slight gamma adjustment
    
    gl_FragColor = vec4(color, 1.0);
  }
`;

function BackgroundShader() {
  const meshRef = useRef<THREE.Mesh>(null);
  const { viewport } = useThree();

  // Create shader material with custom uniforms
  const shaderMaterial = useMemo(() => {
    return new ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        time: { value: 0 },
        resolution: { value: new Vector2() },
        colorA: { value: new Color('#00A3FF') },
        colorB: { value: new Color('#7000FF') },
        colorC: { value: new Color('#FF00E5') }
      }
    });
  }, []);

  // Update uniforms on each frame
  useFrame(({ clock, size }) => {
    if (!meshRef.current) return;
    
    const material = meshRef.current.material as ShaderMaterial;
    material.uniforms.time.value = clock.getElapsedTime();
    material.uniforms.resolution.value.set(size.width, size.height);
  });

  return (
    <mesh ref={meshRef} material={shaderMaterial}>
      <planeGeometry args={[viewport.width, viewport.height, 32, 32]} />
    </mesh>
  );
}

function Scene() {
  const { camera } = useThree();
  
  // Position camera for optimal view
  useMemo(() => {
    camera.position.set(0, 0, 5);
    camera.lookAt(0, 0, 0);
  }, [camera]);

  return (
    <group>
      <BackgroundShader />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} intensity={1} />
    </group>
  );
}

export default function ElegantBackground() {
  return (
    <div className="absolute inset-0" style={{ height: '100vh' }}>
      <Canvas
        gl={{ antialias: true, alpha: true }}
        dpr={[1, 2]}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'transparent'
        }}
      >
        <Scene />
      </Canvas>
    </div>
  );
}
