import { Variants } from 'framer-motion';

export const glowingVariants: Variants = {
  initial: {
    opacity: 0.5,
    scale: 1,
  },
  animate: {
    opacity: [0.5, 1, 0.5],
    scale: [1, 1.02, 1],
    transition: {
      duration: 3,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};

export const floatingVariants: Variants = {
  initial: {
    y: 0,
  },
  animate: {
    y: [-10, 10, -10],
    transition: {
      duration: 6,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};

export const gradientFlow: Variants = {
  initial: {
    backgroundPosition: "0% 50%",
  },
  animate: {
    backgroundPosition: ["0% 50%", "100% 50%", "0% 50%"],
    transition: {
      duration: 10,
      repeat: Infinity,
      ease: "linear",
    },
  },
};

export const revealFromBlur: Variants = {
  hidden: {
    filter: "blur(10px)",
    opacity: 0,
    scale: 0.9,
  },
  visible: {
    filter: "blur(0px)",
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
};

export const buttonHoverVariants = {
  initial: {
    scale: 1,
    boxShadow: "0 0 0 rgba(0, 163, 255, 0)",
  },
  hover: {
    scale: 1.05,
    boxShadow: [
      "0 0 20px rgba(0, 163, 255, 0.3)",
      "0 0 30px rgba(112, 0, 255, 0.3)",
      "0 0 40px rgba(255, 0, 229, 0.3)",
    ],
    transition: {
      duration: 0.3,
      ease: "easeInOut",
    },
  },
};

export const scrollProgressVariants = {
  hidden: { scaleX: 0, transformOrigin: "left" },
  visible: {
    scaleX: 1,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
};

export const cardTiltVariants = {
  rest: {
    rotateX: 0,
    rotateY: 0,
    transition: {
      duration: 0.5,
      type: "tween",
      ease: "easeOut",
    },
  },
  hover: {
    rotateX: [-5, 5],
    rotateY: [-5, 5],
    transition: {
      duration: 0.3,
      type: "spring",
      stiffness: 300,
      damping: 20,
    },
  },
};
