import React from 'react';

const SocialProofTicker = () => {
  const tickerItems = [
    "10x Efficiency",
    "99.9% Uptime",
    "24/7 Support",
    "80% Cost Reduction",
    "10x Efficiency",
    "99.9% Uptime",
    "24/7 Support",
    "80% Cost Reduction",
    "10x Efficiency",
    "99.9% Uptime",
    "24/7 Support"
  ];

  return (
    <div className="w-full overflow-hidden py-4">
      <div className="flex items-center gap-8 animate-scroll">
        {Array(2).fill(null).map((_, arrayIndex) => (
          <div key={arrayIndex} className="flex items-center gap-8 whitespace-nowrap">
            {tickerItems.map((item, index) => (
              <div key={`${arrayIndex}-${index}`} className="flex items-center gap-2">
                <span className="text-gray-400">{item}</span>
                <span className="text-purple-500">•</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
        .animate-scroll {
          animation: scroll 30s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default SocialProofTicker;
