import React, { useEffect } from 'react';

const BookCall = () => {
  useEffect(() => {
    // Load Calendly widget script
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="min-h-screen bg-black py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-display font-bold text-white mb-4">
            Book a Call with Our Expert
          </h1>
          <p className="text-xl text-white/70">
            Schedule a consultation to discuss your AI automation needs
          </p>
        </div>
        <div className="bg-white/5 backdrop-blur-xl rounded-2xl p-6 shadow-2xl">
          <div 
            className="calendly-inline-widget" 
            data-url="https://calendly.com/nathan-syncramatics/30min" 
            style={{ minWidth: '320px', height: '700px' }}
          />
        </div>
      </div>
    </section>
  );
};

export default BookCall;
