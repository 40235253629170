import { useState, useEffect } from 'react';

interface OptimizedImageProps {
  src: string;
  alt: string;
  className?: string;
  priority?: boolean;
}

export const OptimizedImage = ({ src, alt, className, priority = false }: OptimizedImageProps) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check if WebP is supported
    const webpSrc = src.replace(/\.(png|jpg|jpeg)$/, '.webp');
    const img = new Image();
    
    img.onload = () => {
      setImageSrc(webpSrc);
      setIsLoading(false);
    };

    img.onerror = () => {
      // Fallback to original format if WebP fails
      setImageSrc(src);
      setIsLoading(false);
    };

    img.src = webpSrc;
  }, [src]);

  return (
    <div className={`relative overflow-hidden ${className || ''}`}>
      <img 
        src={imageSrc} 
        alt={alt}
        loading={priority ? "eager" : "lazy"}
        decoding={priority ? "sync" : "async"}
        className={`transition-opacity duration-300 ${
          isLoading ? 'opacity-0' : 'opacity-100'
        }`}
      />
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse" />
      )}
    </div>
  );
};
