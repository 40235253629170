import { motion } from 'framer-motion';
import { glowingVariants } from '../utils/advancedMotion';

const Logo = () => {
  return (
    <motion.div
      className="relative flex items-center"
      variants={glowingVariants}
      initial="initial"
      animate="animate"
    >
      {/* Logo Icon */}
      <div className="relative mr-2">
        <div className="absolute inset-0 bg-gradient-to-r from-accent-blue via-accent-purple to-accent-pink blur-lg opacity-50" />
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="relative"
        >
          <path
            d="M16 2L2 9L16 16L30 9L16 2Z"
            className="stroke-accent-blue"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 23L16 30L30 23"
            className="stroke-accent-purple"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 16L16 23L30 16"
            className="stroke-accent-pink"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Logo Text */}
      <div className="font-display font-bold text-2xl">
        <span className="bg-gradient-to-r from-accent-blue via-accent-purple to-accent-pink text-transparent bg-clip-text">
          Syncra
        </span>
        <span className="text-white">
          Matic
        </span>
      </div>

      {/* Animated Dots */}
      <motion.div
        className="absolute -right-4 top-0 flex space-x-1"
        animate={{
          opacity: [0.5, 1, 0.5],
          transition: {
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
          },
        }}
      >
        <div className="w-1 h-1 rounded-full bg-accent-blue" />
        <div className="w-1 h-1 rounded-full bg-accent-purple" />
        <div className="w-1 h-1 rounded-full bg-accent-pink" />
      </motion.div>
    </motion.div>
  );
};

export default Logo;
