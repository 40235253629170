import { motion } from 'framer-motion';
import { fadeIn, staggerContainer } from '../utils/motion';
import { useScrollAnimation, useParallax } from '../hooks/useScrollAnimation';
import ElegantBackground from './Effects/ElegantBackground';
import SocialProofTicker from './advanced/SocialProofTicker';
import { VapiWidget } from './VapiWidget';
import { useState, useEffect } from 'react';

const Hero = () => {
  const { ref: headingRef, isVisible: headingVisible } = useScrollAnimation({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [currentWord, setCurrentWord] = useState('Outthink')
  const words = ['Outthink', 'Outwork', 'Outperform']

  useEffect(() => {
    let currentIndex = 0
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % words.length
      setCurrentWord(words[currentIndex])
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  return (
    <section className="relative min-h-screen overflow-hidden bg-[#0a0118]" id="hero">
      {/* Background */}
      <div className="absolute inset-0 z-0">
        <ElegantBackground />
      </div>

      {/* Content */}
      <div className="relative z-10">
        <motion.div
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
          className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-screen flex flex-col justify-center"
        >
          <div className="text-center space-y-12 sm:space-y-16 pt-32 sm:pt-0">
            <motion.div
              ref={headingRef}
              className={`scroll-animate fade-up ${headingVisible ? 'is-visible' : ''}`}
              variants={fadeIn('up', 'tween', 0.2)}
            >
              <h1 className="text-4xl sm:text-6xl lg:text-7xl font-display font-bold mb-6 leading-tight tracking-tight text-center">
                <div className="mb-2 text-white">AI Agents That</div>
                <div className="flex items-center justify-center relative h-[1.2em]">
                  <div className="flex items-center justify-center absolute inset-0">
                    <div className="w-[200px] sm:w-[300px] lg:w-[400px] flex justify-end">
                      <div 
                        className="text-transparent bg-clip-text bg-gradient-to-r from-accent-blue via-accent-purple to-accent-pink opacity-0 animate-fadeIn"
                        key={currentWord}
                        style={{
                          animation: 'fadeInOut 3s infinite',
                        }}
                      >
                        {currentWord}
                      </div>
                    </div>
                    <div className="text-white ml-4">Humans</div>
                  </div>
                </div>
              </h1>
              <div className="text-base sm:text-lg lg:text-xl text-white/80 max-w-3xl mx-auto px-4">
                <p className="sm:whitespace-nowrap">
                  Reduce operational costs by{' '}
                  <span className="relative inline-block">
                    <span className="absolute -inset-1 bg-gradient-to-r from-[#00A3FF] to-[#7000FF] blur-lg opacity-20"></span>
                    <span className="relative font-medium text-transparent bg-clip-text bg-gradient-to-r from-[#00A3FF] to-[#7000FF]">80%</span>
                  </span>{' '}
                  while achieving{' '}
                  <span className="relative inline-block">
                    <span className="absolute -inset-1 bg-gradient-to-r from-[#00A3FF] to-[#7000FF] blur-lg opacity-20"></span>
                    <span className="relative font-medium text-transparent bg-clip-text bg-gradient-to-r from-[#00A3FF] to-[#7000FF]">10x</span>
                  </span>{' '}
                  efficiency
                </p>
              </div>
            </motion.div>

            <motion.div
              variants={fadeIn('up', 'tween', 0.4)}
              className="flex flex-col sm:flex-row gap-6 justify-center items-center"
            >
              <button className="relative group">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-accent-blue via-accent-purple to-accent-pink rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
                <a
                  href="#roi-calculator"
                  className="relative px-8 py-4 bg-black rounded-lg leading-none flex items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector('#roi-calculator')?.scrollIntoView({
                      behavior: 'smooth'
                    });
                  }}
                >
                  Calculate ROI
                  <span className="ml-2">↓</span>
                </a>
              </button>
              <VapiWidget />
            </motion.div>

            {/* Social Proof Ticker */}
            <SocialProofTicker />
          </div>
        </motion.div>

        {/* Scroll Indicator */}
        <motion.div
          variants={fadeIn('up', 'tween', 0.8)}
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
        >
          <div className="animate-bounce">
            <svg
              className="w-6 h-6 text-white/60"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 14l-7 7m0 0l-7-7m7 7V3"
              />
            </svg>
          </div>
        </motion.div>
      </div>
      <style jsx>{`
        @keyframes fadeInOut {
          0% {
            opacity: 0;
            transform: translateY(10px);
          }
          10%, 90% {
            opacity: 1;
            transform: translateY(0);
          }
          100% {
            opacity: 0;
            transform: translateY(-10px);
          }
        }
      `}</style>
    </section>
  );
};

export default Hero;
