import React from 'react';

const PrivacyNotice: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-100 pt-24 md:pt-32">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">PRIVACY NOTICE</h1>
          <p className="text-gray-400">Last updated December 13, 2024</p>
        </div>

        <div className="prose prose-invert max-w-none">
          <div className="mb-12">
            <p className="mb-6 text-base sm:text-lg">
              This Privacy Notice for syncramatic llc ("we," "us," or "our"), describes how and why we might access, collect, store, use, and/or share ("process") your personal information when you use our services ("Services"), including when you:
            </p>
            <ul className="list-disc pl-6 mb-6 space-y-2 text-sm sm:text-base">
              <li>Visit our website at https://syncramatic.com, or any website of ours that links to this Privacy Notice</li>
              <li>Download and use our application(s), such as our desktop or mobile application</li>
              <li>Engage with us in other related ways, including any sales, marketing, or events</li>
            </ul>
            <p className="mb-6 text-base sm:text-lg">
              Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services.
            </p>
          </div>

          {/* Summary Section */}
          <div className="mb-12 bg-gray-800 p-4 sm:p-6 rounded-lg">
            <h2 className="text-xl sm:text-2xl font-bold mb-4">SUMMARY OF KEY POINTS</h2>
            
            <p className="mb-6 text-sm sm:text-base">This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.</p>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">What personal information do we process?</h3>
                <p className="text-sm sm:text-base">
                  When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. <a href="#personal-info" className="text-blue-400 hover:text-blue-300">Learn more about personal information you disclose to us</a>.
                </p>
              </div>

              <div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">Do we process any sensitive personal information?</h3>
                <p className="text-sm sm:text-base">
                  Some of the information may be considered "special" or "sensitive" in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We do not process sensitive personal information.
                </p>
              </div>

              <div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">Do we collect any information from third parties?</h3>
                <p className="text-sm sm:text-base">
                  We may collect information from public databases, marketing partners, social media platforms, and other outside sources.
                </p>
              </div>

              <div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">How do we process your information?</h3>
                <p className="text-sm sm:text-base">
                  We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. <a href="#process-info" className="text-blue-400 hover:text-blue-300">Learn more about how we process your information</a>.
                </p>
              </div>

              <div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">In what situations and with which parties do we share personal information?</h3>
                <p className="text-sm sm:text-base">
                  We may share information in specific situations and with specific third parties. <a href="#share-info" className="text-blue-400 hover:text-blue-300">Learn more about when and with whom we share your personal information</a>.
                </p>
              </div>

              <div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">What are your rights?</h3>
                <p className="text-sm sm:text-base">
                  Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. <a href="#privacy-rights" className="text-blue-400 hover:text-blue-300">Learn more about your privacy rights</a>.
                </p>
              </div>

              <div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">How do you exercise your rights?</h3>
                <p className="text-sm sm:text-base">
                  The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                </p>
              </div>

              <div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">Want to learn more about what we do with any information we collect?</h3>
                <p className="text-sm sm:text-base">
                  <a href="#full-notice" className="text-blue-400 hover:text-blue-300">Review the Privacy Notice in full</a>.
                </p>
              </div>
            </div>
          </div>

          <div className="mb-12">
            <h2 className="text-xl sm:text-2xl font-bold mb-6">TABLE OF CONTENTS</h2>
            <ol className="list-decimal pl-4 sm:pl-6 space-y-2 text-sm sm:text-base">
              {[
                "WHAT INFORMATION DO WE COLLECT?",
                "HOW DO WE PROCESS YOUR INFORMATION?",
                "WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?",
                "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?",
                "DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",
                "HOW DO WE HANDLE YOUR SOCIAL LOGINS?",
                "HOW LONG DO WE KEEP YOUR INFORMATION?",
                "WHAT ARE YOUR PRIVACY RIGHTS?",
                "CONTROLS FOR DO-NOT-TRACK FEATURES",
                "DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?",
                "DO WE MAKE UPDATES TO THIS NOTICE?",
                "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?",
                "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"
              ].map((item, index) => (
                <li key={index}>
                  <a href={`#section${index + 1}`} className="text-blue-400 hover:text-blue-300 transition-colors">
                    {item}
                  </a>
                </li>
              ))}
            </ol>
          </div>

          {/* Main Content */}
          <div id="full-notice" className="space-y-12">
            {/* Section 1 */}
            <section id="personal-info" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">1. WHAT INFORMATION DO WE COLLECT?</h2>
              
              <div className="space-y-8">
                {/* Personal Information Section */}
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-4">Personal information you disclose to us</h3>
                  <p className="italic mb-4 text-sm sm:text-base">
                    In Short: We collect personal information that you provide to us.
                  </p>
                  
                  <p className="mb-4 text-base sm:text-lg">
                    We collect personal information that you voluntarily provide to us when you register on the Services,
                    express an interest in obtaining information about us or our products and Services, when you participate in activities
                    on the Services, or otherwise when you contact us.
                  </p>
                  
                  <p className="mb-4 text-base sm:text-lg">
                    We collect personal information that you voluntarily provide to us when you register on the Services,
                    express an interest in obtaining information about us or our products and Services, when you participate in activities
                    on the Services, or otherwise when you contact us.
                  </p>
                  
                  <p className="mb-4 text-base sm:text-lg">
                    <span className="font-semibold">Personal Information Provided by You.</span> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                  </p>
                  
                  <ul className="list-disc pl-4 sm:pl-6 mb-4 space-y-2 text-sm sm:text-base">
                    <li>phone numbers</li>
                    <li>email addresses</li>
                    <li>job titles</li>
                    <li>contact or authentication data</li>
                    <li>billing addresses</li>
                    <li>debit/credit card numbers</li>
                  </ul>

                  <p className="mb-4 text-base sm:text-lg">
                    <span className="font-semibold">Sensitive Information.</span> We do not process sensitive personal information.
                  </p>

                  <p className="mb-4 text-base sm:text-lg">
                    All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                  </p>
                </div>

                {/* Automatically Collected Information Section */}
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-4">Information automatically collected</h3>
                  <p className="italic mb-4 text-sm sm:text-base">
                    In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                  </p>
                  
                  <p className="mb-4 text-base sm:text-lg">
                    We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                  </p>

                  <p className="mb-4 text-base sm:text-lg">
                    Like many businesses, we also collect information through cookies and similar technologies.
                  </p>
                </div>
              </div>
            </section>

            {/* Section 2 */}
            <section id="process-info" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
              
              <p className="italic mb-4 text-sm sm:text-base">
                In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
              </p>
              <ul className="list-disc pl-4 sm:pl-6 mb-4 space-y-2 text-sm sm:text-base">
                <li>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
              </ul>
            </section>

            {/* Section 3 */}
            <section id="section3" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
              
              <p className="italic mb-4 text-sm sm:text-base">
                In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                If you are located in Canada, this section applies to you.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
              </p>
              <ul className="list-disc pl-4 sm:pl-6 mb-4 space-y-2 text-sm sm:text-base">
                <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                <li>For investigations and fraud detection and prevention</li>
                <li>For business transactions provided certain conditions are met</li>
                <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
                <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
                <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
                <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
                <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
                <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                <li>If the information is publicly available and is specified by the regulations</li>
              </ul>
            </section>

            {/* Section 4 */}
            <section id="share-info" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
              
              <p className="italic mb-4 text-sm sm:text-base">
                In Short: We may share information in specific situations described in this section and/or with the following third parties.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                We may need to share your personal information in the following situations:
              </p>
              <ul className="list-disc pl-4 sm:pl-6 mb-4 space-y-2 text-sm sm:text-base">
                <li><span className="font-semibold">Business Transfers.</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                <li><span className="font-semibold">Affiliates.</span> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
                <li><span className="font-semibold">Business Partners.</span> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
              </ul>
            </section>

            {/* Section 5 */}
            <section id="section5" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
              
              <p className="italic mb-4 text-sm sm:text-base">
                In Short: We may use cookies and other tracking technologies to collect and store your information.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security and operation of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                To the extent these online tracking technologies are deemed to be a "sale"/"sharing" (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section "DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
              </p>
            </section>

            {/* Section 6 */}
            <section id="section6" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
              
              <p className="italic mb-4 text-sm sm:text-base">
                In Short: If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                We will use the information we receive only for the purposes that are described in this Privacy Notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
              </p>
            </section>

            {/* Section 7 */}
            <section id="section7" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
              
              <p className="italic mb-4 text-sm sm:text-base">
                In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of use.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
              </p>
            </section>

            {/* Section 8 */}
            <section id="privacy-rights" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
              
              <p className="italic mb-4 text-sm sm:text-base">
                In Short: Depending on your state of residence in the US or in some regions, such as Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                In some regions (like Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                We will consider and act upon any request in accordance with applicable data protection laws.
              </p>
              
              <div className="mb-6">
                <p className="font-semibold mb-2">Withdrawing your consent:</p>
                <p className="mb-4 text-base sm:text-lg">
                  If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
                </p>
                
                <p className="mb-4 text-base sm:text-lg">
                  However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                </p>
              </div>
              
              <div className="mb-6">
                <p className="font-semibold mb-2">Account Information</p>
                <p className="mb-4 text-base sm:text-lg">
                  If you would at any time like to review or change the information in your account or terminate your account, you can:
                </p>
                <ul className="list-disc pl-8 mb-4 text-sm sm:text-base">
                  <li>Log in to your account settings and update your user account.</li>
                  <li>Contact us using the contact information provided.</li>
                </ul>
                <p className="mb-4 text-base sm:text-lg">
                  Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
                </p>
              </div>
            </section>

            {/* Section 9 */}
            <section id="section9" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
              
              <p className="mb-4 text-base sm:text-lg">
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.
              </p>
            </section>

            {/* Section 10 */}
            <section id="section10" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
              
              <p className="italic mb-4 text-sm sm:text-base">
                In Short: If you are a resident of the United States, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.
              </p>
              
              <h3 className="text-lg sm:text-xl font-semibold mb-4">Categories of Personal Information We Collect</h3>
              
              <p className="mb-4 text-base sm:text-lg">
                We have collected the following categories of personal information in the past twelve (12) months:
              </p>
              
              <div className="overflow-x-auto mb-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr className="bg-gray-50">
                      <th scope="col" className="w-1/6 px-4 py-3 text-left text-sm font-semibold text-gray-900">Category</th>
                      <th scope="col" className="w-4/6 px-4 py-3 text-left text-sm font-semibold text-gray-900">Examples</th>
                      <th scope="col" className="w-1/6 px-4 py-3 text-left text-sm font-semibold text-gray-900">Collected</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    <tr className="bg-white">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">A. Identifiers</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
                      <td className="px-4 py-3 text-sm text-gray-700">YES</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">B. Protected classification characteristics</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</td>
                      <td className="px-4 py-3 text-sm text-gray-700">YES</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">C. Commercial information</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Transaction information, purchase history, financial details, and payment information</td>
                      <td className="px-4 py-3 text-sm text-gray-700">YES</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">D. Biometric information</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Fingerprints and voiceprints</td>
                      <td className="px-4 py-3 text-sm text-gray-700">NO</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">E. Internet or network activity</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                      <td className="px-4 py-3 text-sm text-gray-700">YES</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">F. Geolocation data</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Device location</td>
                      <td className="px-4 py-3 text-sm text-gray-700">YES</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">G. Audio, electronic, visual information</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Images and audio, video or call recordings created in connection with our business activities</td>
                      <td className="px-4 py-3 text-sm text-gray-700">YES</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">H. Professional information</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                      <td className="px-4 py-3 text-sm text-gray-700">YES</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">I. Education Information</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Student records and directory information</td>
                      <td className="px-4 py-3 text-sm text-gray-700">YES</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">J. Inferences</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics</td>
                      <td className="px-4 py-3 text-sm text-gray-700">NO</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">K. Sensitive personal Information</td>
                      <td className="px-4 py-3 text-sm text-gray-700">Social security numbers, driver's license numbers, passport numbers, or similar identifiers</td>
                      <td className="px-4 py-3 text-sm text-gray-700">NO</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="mb-4 text-base sm:text-lg">
                We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
              </p>
              <ul className="list-disc pl-8 mb-6 text-sm sm:text-base">
                <li>Receiving help through our customer support channels;</li>
                <li>Participation in customer surveys or contests; and</li>
                <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
              </ul>

              <h3 className="text-lg sm:text-xl font-semibold mb-4">Sources of Personal Information</h3>
              <p className="mb-4 text-base sm:text-lg">
                Learn more about the sources of personal information we collect in "WHAT INFORMATION DO WE COLLECT?"
              </p>

              <h3 className="text-lg sm:text-xl font-semibold mb-4">How We Use and Share Personal Information</h3>
              <p className="mb-4 text-base sm:text-lg">
                Learn more about how we use your personal information in the section, "HOW DO WE PROCESS YOUR INFORMATION?"
              </p>

              <h3 className="text-lg sm:text-xl font-semibold mb-4">Will your information be shared with anyone else?</h3>
              <p className="mb-6 text-base sm:text-lg">
                We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
              </p>
              
              <p className="mb-6 text-base sm:text-lg">
                We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
              </p>

              <h3 className="text-lg sm:text-xl font-semibold mb-4">Your Rights</h3>
              <p className="mb-4 text-base sm:text-lg">
                You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
              </p>
              <ul className="list-disc pl-8 mb-6 text-sm sm:text-base">
                <li>Right to know whether or not we are processing your personal data</li>
                <li>Right to access your personal data</li>
                <li>Right to correct inaccuracies in your personal data</li>
                <li>Right to request the deletion of your personal data</li>
                <li>Right to obtain a copy of the personal data you previously shared with us</li>
                <li>Right to non-discrimination for exercising your rights</li>
                <li>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</li>
              </ul>

              <h3 className="text-lg sm:text-xl font-semibold mb-4">How to Exercise Your Rights</h3>
              <p className="mb-4 text-base sm:text-lg">
                To exercise these rights, you can contact us by submitting a data subject access request, by emailing us at info@syncramatic.com, or by referring to the contact details at the bottom of this document.
              </p>

              <p className="mb-6 text-base sm:text-lg">
                Under certain US state data protection laws, you can designate an authorized agent to make a request on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.
              </p>

              <h3 className="text-lg sm:text-xl font-semibold mb-4">Request Verification</h3>
              <p className="mb-4 text-base sm:text-lg">
                Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.
              </p>

              <p className="mb-4 text-base sm:text-lg">
                If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
              </p>
            </section>

            {/* Section 11 */}
            <section id="section11" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
              
              <p className="italic mb-4 text-sm sm:text-base">
                In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
              </p>
              
              <p className="mb-4 text-base sm:text-lg">
                We may update this Privacy Notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.
              </p>
            </section>

            {/* Section 12 */}
            <section id="section12" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
              
              <p className="mb-4 text-base sm:text-lg">
                If you have questions or comments about this notice, you may contact us by post at:
              </p>
              
              <div className="mb-4 pl-4 border-l-4 border-gray-200">
                <p className="mb-1">Syncramatic LLC</p>
                <p className="mb-1">Dallas, TX</p>
                <p>United States</p>
              </div>
            </section>

            {/* Section 13 */}
            <section id="section13" className="mb-12">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
              <p className="mb-4 text-base sm:text-lg">
                Based on the applicable laws of your country or state of residence in the US, you may have the right to request
                access to the personal information we collect from you, details about how we have processed it, correct inaccuracies,
                or delete your personal information. You may also have the right to withdraw your consent to our processing of your
                personal information. These rights may be limited in some circumstances by applicable law.
              </p>
              <p className="mb-4 text-base sm:text-lg">
                To request to review, update, or delete your personal information, please reach out to{' '}
                <a href="mailto:info@syncramatic.com" className="text-blue-400 hover:text-blue-300 transition-colors">
                  info@syncramatic.com
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyNotice;
