import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import BookCall from './components/BookCall';
import Hero from './components/Hero';
import Solutions from './components/Solutions';
import ROICalculator from './components/ROICalculator';
import WhyChoose from './components/WhyChoose';
import Contact from './components/Contact';
import UseCases from './components/UseCases';
import PrivacyNotice from './components/PrivacyNotice';
import TermsOfService from './components/TermsOfService';
import What from './components/What';

function App() {
  useEffect(() => {
    // Smooth scroll behavior
    document.documentElement.style.scrollBehavior = 'smooth';
    
    // Gradient background animation
    const bg = document.querySelector('.gradient-bg');
    if (bg) {
      bg.classList.add('animate-gradient');
    }
    return () => {
      document.documentElement.style.scrollBehavior = 'auto';
    };
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-[#0A0A0A] text-white relative overflow-hidden">
        {/* Global animated gradient background */}
        <div className="gradient-bg fixed inset-0 opacity-20" />
        
        {/* Smooth transition overlays */}
        <div className="fixed inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-transparent via-black/50 to-black pointer-events-none" />
        <div className="fixed inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-40 pointer-events-none" />
        
        {/* Content */}
        <div className="relative z-10">
          <Navbar />
          <Routes>
            <Route path="/" element={
              <main className="relative">
                <div className="absolute inset-0 bg-gradient-to-b from-accent-blue/5 via-accent-purple/5 to-accent-pink/5 opacity-20 pointer-events-none" />
                <Hero />
                <What />
                <UseCases />
                <Solutions />
                <WhyChoose />
                <ROICalculator />
                <Contact />
              </main>
            } />
            <Route path="/book-call" element={<BookCall />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyNotice />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
